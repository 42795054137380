import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { Box } from "../../components/StyledComponent";
import Showcase from "./Showcase/Showcase";
import { isMobile } from "../../constants";

export default function Home() {
  return (
    <Box className="text-white min-h-[788px] md:h-screen min-w-[1536px] md:w-screen flex flex-col justify-start md:justify-center items-center overflow-hidden">
      <Showcase />
    </Box>
  );
}

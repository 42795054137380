import {
  Box
} from "../../../components/StyledComponent";
import {
  faX,
  faClose
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RoutePattern } from "../../../routes/RoutePattern";
import styled from "styled-components";
import Button from "../../../components/Form/Button";
import { isMobile } from "../../../constants";

import Swal from "sweetalert2";
import $ from "jquery";
import Parallax from "parallax-js";

import OUTSIDE from "../../../assets/videos/Club Maeta Outside to Inside Animation.mp4";
import INSIDE from "../../../assets/videos/Club Maeta Inside Animation.mp4";
import ORANGEBLINK from "../../../assets/videos/Desktop Orange Light Blink.mp4";
import PURPLEBLINK from "../../../assets/videos/Desktop Purple Light Blink.mp4";
import PURPLE2ORANGE from "../../../assets/videos/Desktop Orange to Purple.mp4";
import ORANGE2PURPLE from "../../../assets/videos/Desktop Purple to Orange.mp4";
import BARVID from "../../../assets/videos/Club Maeta Bar.mp4";

import OUTSIDEPOSTER from "../../../assets/images/Desktop_Outside to Inside.png";
import VIDPOSTER from "../../../assets/images/Maeta img.png";
import PURPLEPOSTER from "../../../assets/images/Desktop Purple to Orange.png";
import ORANGEPOSTER from "../../../assets/images/Desktop Orange to Purple.png";

function iOS() {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
  // iPad on iOS 13 detection
  || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

function isSafari() {
  return navigator.vendor && navigator.vendor.indexOf('Apple') > -1 && navigator.userAgent && navigator.userAgent.indexOf('CriOS') == -1 && navigator.userAgent.indexOf('FxiOS') == -1;
}

const VideoPlayer = styled.video`
`;

const VideoContainer = styled.div`
  @media (max-width: 820px) {
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    position: absolute;
  }
`;

const Showcase = () => {
  const videoRef: any = useRef(null);
  const blinkingRef: any = useRef(null);
  const transitionRef: any = useRef(null);
  const barVideRef: any = useRef(null);
  const [usersEmail, setUsersEmail] = useState<string>("");
  const [changeColor, setChangeColor] = useState<boolean>(false);
  const [showPopupTable, setShowPopupTable] = useState<boolean>(false);
  const [showPopupRecipe, setShowPopupRecipe] = useState<boolean>(false);
  const [videoEnded, setVideoEnded] = useState({
    outside: false,
    inside: false,
    transition: false
  });

  useEffect(() => {
    const video = videoRef.current as HTMLVideoElement;
    video.pause();

    showSwal();

    // if (!isMobile) {
      var scene = document.getElementById('scene');
      var parallaxInstance = new Parallax(scene);
    // }
  
  }, []);

  useEffect(() => {
    if (isMobile) {
      if (showPopupTable || showPopupRecipe) {
        $("body").css('overflow-x', 'hidden');
      } else {
        $("body").css('overflow-x', 'auto');
      }
    }
  }, [showPopupTable, showPopupRecipe]);

  // this handles if video is ended
  useEffect(() => {
    const video = videoRef.current as HTMLVideoElement;
    const vidBlink = blinkingRef.current as HTMLVideoElement;
    if (videoEnded.outside && !videoEnded.inside) {
      $("body").css('justify-content', 'flex-start');
      video.src = INSIDE;
      video.poster = "";
      video.play();
      video.onended = () => {
        setVideoEnded({...videoEnded, inside: true})
      }
      video.ontimeupdate = (e) => {
        // console.log(video.currentTime);
        if (video.currentTime >= 2.6) {
          const children = $("#action_container").children();
          let x = 1;
          $(children[0]).animate({
            opacity: 1
          }, 'fast');
          let showChildren = setInterval(function() {
            if (x >= children.length) {
              clearInterval(showChildren);
              $("#video_player").hide();
              $("#video_background").show();
              vidBlink.play();
            }
            $(children[x]).animate({
              opacity: 1
            }, 'fast');
            x++;
          }, 500);
        }
      }
    }
  }, [videoEnded]);

  useEffect(() => {
    const video = videoRef.current as HTMLVideoElement;
    const vidBlink = blinkingRef.current as HTMLVideoElement;
    const vidTransition = transitionRef.current as HTMLVideoElement;
    // console.log(videoEnded.inside);
    if (videoEnded.inside) {
      vidTransition.play();
      vidTransition.pause();
      vidTransition.currentTime = 0;

      if (changeColor) {
        vidTransition.play();
        $("#video_background").hide();
        $("#video_transition").show();
        vidBlink.src = PURPLEBLINK;
        setTimeout(function() {
          $("#video_transition").hide();
          $("#video_background").show();
          vidBlink.play();
          vidTransition.poster = ORANGEPOSTER;
          vidTransition.src = PURPLE2ORANGE;
        }, 1000);
      } else {
        if (isMobile) vidTransition.load();
        $("#video_background").hide();
        $("#video_transition").show();
        vidTransition.play();
        vidBlink.src = ORANGEBLINK;
        setTimeout(function() {
          $("#video_transition").hide();
          $("#video_background").show();
          vidBlink.play();
          vidTransition.poster = PURPLEPOSTER;
          vidTransition.src = ORANGE2PURPLE;
        }, 1000);
      }
    }
  },[changeColor]);

  // function changeTheColor() {
  //   console.log('test');
  //   const vidBlink = blinkingRef.current as HTMLVideoElement;
    
  //   vidBlink.ontimeupdate = (e) => {
  //     if (!videoEnded.transition && vidBlink.currentTime > 1.8) {
  //       console.log(vidBlink.currentTime);
  //       setChangeColor(prevStatus => !prevStatus);
  //     }
  //   }

  //   setVideoEnded({...videoEnded, transition: true});
  // }

  const showSwal = () => {
    Swal.fire({
      // title: 'Error!',
      title: 'Show Your ID To Enter Club Maeta',
      input: "email",
      inputPlaceholder: "EMAIL ADDRESS",
      inputAttributes: {
        autocapitalize: "off"
      },
      confirmButtonColor: "#000000",
      confirmButtonText: "ENTER",
      showLoaderOnConfirm: true,
      allowOutsideClick: false,
      backdrop: false,
      customClass: {
        title: `!uppercase ${isMobile ? "!text-2xl" : "!text-3xl"}`
      },
      preConfirm: async (email) => {
        try {
          const data = {
            Email: email,
          };
          const response = await enterClub(data);

          return response;
        } catch (error) {
          Swal.showValidationMessage(`
            Request Failed: ${error}
          `);
        }
      },
      willOpen: () => {
        scrollTop();
      },
      willClose: () => {
        scrollTop();
      }
    })
  }

  function enterClub(data: any) {
    const video = videoRef.current as HTMLVideoElement;

    video.play();

    setUsersEmail(data.Email);
  }

  function sendData(data: any) {
    Swal.fire({
      title: 'SUBMITTING PHONE NUMBER...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      willOpen: () => {
        scrollTop();
      },
      willClose: () => {
        scrollTop();
      }
    });
    Swal.showLoading();

    $.ajax({
      url: "https://script.google.com/macros/s/AKfycbwTTYvuRlzFYrq8A9WRwtKY8cNnFCFwoMiotQcrwFKe7UU-2HVv3yoHcTe0AvcTPu-v/exec",
      type: "POST",
      data: data
    }).done(function() {
      Swal.fire({
        title: 'SUCCESS',
        text: 'THANK YOU FOR SUBMITTING',
        icon: 'success',
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonColor: '#000000',
        willOpen: () => {
          scrollTop();
        },
        willClose: () => {
          scrollTop();
        }
      });
    });
  }

  function scrollTop() {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }
  
  return (
    <>
      <Box
        id={RoutePattern.HOME}
        className={`relative flex justify-center items-center h-[93vh] md:h-[103%] w-[103%]`}
      >
        <Box id="scene" className="z-30 flex flex-col justify-center items-center h-full w-full">
          <VideoContainer
            className="h-full w-full align-center"
            data-depth="0.065"
          >
            <VideoPlayer
              id="video_transition"
              ref={transitionRef}
              preload="auto"
              playsInline
              muted
              src={ORANGE2PURPLE}
              className="h-full w-full object-cover block object-center hidden"
            ></VideoPlayer>
            <VideoPlayer
              id="video_background"
              ref={blinkingRef}
              preload="auto"
              playsInline
              muted
              loop
              src={ORANGEBLINK}
              className="h-full w-full object-cover block object-center hidden"
            ></VideoPlayer>
            <VideoPlayer
              id="video_player"
              ref={videoRef}
              preload="metadata"
              playsInline
              muted
              src={OUTSIDE}
              poster={OUTSIDEPOSTER}
              onEnded={() => {
                if (!videoEnded.outside) {
                  setVideoEnded({...videoEnded, outside: true})
                }
              }}
              className="h-full w-full object-cover block object-center"
            ></VideoPlayer>
          </VideoContainer>
          <Box
            id="action_container"
            className="absolute z-20 h-full w-full block" 
            data-depth="0.1"
          >
            <div className="absolute top-[22rem] 3xl:top-[26.5rem] flex justify-center items-center opacity-0">
              <div className="flex flex-col w-[240px] 3xl:w-[300px]">
                <div 
                  className="text-center uppercase cursor-pointer mb-2"
                  onClick={() => {
                    setShowPopupRecipe(true);
                  }}
                >maeta's<br/>favorite drink</div>
                <div className="flex justify-center items-center w-full">
                  {/* <hr className="opacity-0 w-full"/> */}
                  <div
                    className="bg-white border border-white rounded-full p-2 w-1 cursor-pointer pulse"
                    style={{animationDelay: Math.floor(Math.random() * 3000) + 'ms'}}
                    onClick={() => {
                      setShowPopupRecipe(true);
                    }}
                  ></div>
                  {/* <hr className="bg-white w-full"/> */}
                </div>
              </div>
              {/* <div
                className="flex justify-center items-center w-72 h-40 mt-14 bg-white/75 text-black rounded-2xl uppercase text-3xl cursor-pointer"
                onClick={() => {
                  setShowPopupRecipe(true);
                }}
              >
                TBA
              </div> */}
            </div>
            <div className="absolute bottom-32 left-[23rem] 3xl:bottom-36 3xl:left-[30rem] flex flex-col justify-center items-center opacity-0">
              <div
                className="uppercase cursor-pointer mb-1"
                onClick={() => {
                  setShowPopupTable(true);
                }}
              >Maeta at the club</div>
              <div
                className="bg-white rounded-full p-2 w-1 cursor-pointer pulse"
                style={{animationDelay: Math.floor(Math.random() * 3000) + 'ms'}}
                onClick={() => {
                  setShowPopupTable(true);
                }}
              ></div>
            </div>
            <div className="absolute top-[23rem] right-[10%] 3xl:top-[28rem] 3xl:right-[10.5%] flex flex-col justify-center items-center opacity-0">
              <div
                className="uppercase text-center leading-none cursor-pointer mb-2"
                onClick={() => {
                  window.open('https://maeta.komi.io/#bf714197-7be8-4b1d-ac93-e065b0af6a1f');
                }}
              >dj got me<br/>single</div>
              <div
                className="bg-white rounded-full p-2 w-1 cursor-pointer pulse"
                style={{animationDelay: Math.floor(Math.random() * 3000) + 'ms'}}
                onClick={() => {
                  window.open('https://maeta.komi.io/#bf714197-7be8-4b1d-ac93-e065b0af6a1f');
                }}
              ></div>
            </div>
            <div className="absolute bottom-[14rem] right-[4%] 3xl:bottom-[17rem] 3xl:right-[4%] flex flex-col justify-center items-center opacity-0">
              <div
                className="bg-white rounded-full px-6 py-2 w-1 cursor-pointer text-black flex justify-center items-center text-2xl pulse"
                style={{animationDelay: Math.floor(Math.random() * 3000) + 'ms'}}
                onClick={() => {
                  window.open('http://maetasworld.com/');
                }}
              >M</div>
            </div>
            <div className="absolute top-[16rem] left-[57%] 3xl:top-[19rem] 3xl:left-[57.5%] flex flex-col justify-center items-center opacity-0">
              <div
                className="bg-white rounded-full p-2 w-1 cursor-pointer pulse"
                style={{animationDelay: Math.floor(Math.random() * 3000) + 'ms'}}
                onClick={() => {
                  const vidBlink = blinkingRef.current as HTMLVideoElement;
                  const transitionVid = transitionRef.current as HTMLVideoElement;
                  if (isMobile) transitionVid.load();
                  // console.log(vidBlink.currentTime, transitionVid.readyState);
                  const checkBlink = setInterval(function() {
                    if (vidBlink.currentTime > 1.8 && transitionVid.readyState == 4) {
                      setChangeColor(prevStatus => !prevStatus);
                      clearInterval(checkBlink);
                    }
                  }, 100);
                }}
              ></div>
              <div
                className="uppercase cursor-pointer"
                onClick={() => {
                  const vidBlink = blinkingRef.current as HTMLVideoElement;
                  const transitionVid = transitionRef.current as HTMLVideoElement;
                  if (isMobile) transitionVid.load();

                  const checkBlink = setInterval(function() {
                    console.log(vidBlink.currentTime, transitionVid.readyState);
                    if (vidBlink.currentTime > 1.8 && transitionVid.readyState == 4) {
                      setChangeColor(prevStatus => !prevStatus);
                      clearInterval(checkBlink);
                    }
                  }, 100);
                }}
              >CLICK HERE</div>
            </div>
          </Box>
          {/* <Box id="mobile_view" className="absolute z-20 w-full h-full flex md:hidden justify-center items-center">
            <div className="flex justify-center items-center text-center w-11/12 h-56 mt-14 bg-white/75 text-black rounded-2xl uppercase text-2xl p-10">
              This site is best viewed using desktop computers.
            </div>
          </Box> */}
        </Box>
      </Box>
      
      {/* {!isMobile ? ( */}
        <Box
          className="absolute z-50 h-[102.75%] w-[102.75%] block"
        >
          <div className="absolute top-[22rem] 3xl:top-[26.5rem] flex justify-center items-center opacity-0">
            <div className="flex flex-col w-[240px] 3xl:w-[300px]">
              <div 
                className="text-center uppercase cursor-pointer mb-2"
                onClick={() => {
                  setShowPopupRecipe(true);
                }}
              >maeta's<br/>favorite drink</div>
              <div className="flex justify-center items-center w-full">
                {/* <hr className="opacity-0 w-full"/> */}
                <div
                  className="bg-white border border-white rounded-full p-2 w-3 h-3 ml-[1.75rem] cursor-pointer"
                  onClick={() => {
                    setShowPopupRecipe(true);
                  }}
                ></div>
                {/* <hr className="bg-white w-full"/> */}
              </div>
            </div>
            {/* <div
              className="flex justify-center items-center w-72 h-40 mt-14 bg-white/75 text-black rounded-2xl uppercase text-3xl cursor-pointer"
              onClick={() => {
                setShowPopupRecipe(true);
              }}
            >
              TBA
            </div> */}
          </div>
          <div className="absolute bottom-32 left-[23rem] 3xl:bottom-36 3xl:left-[30rem] flex flex-col justify-center items-center opacity-0">
            <div
              className="uppercase cursor-pointer mb-1"
              onClick={() => {
                setShowPopupTable(true);
              }}
            >Maeta at the club</div>
            <div
              className="bg-white rounded-full p-2 w-3 h-3 ml-[1.75rem] ml-[0.6rem] mb-[0.35rem] cursor-pointer"
              onClick={() => {
                setShowPopupTable(true);
              }}
            ></div>
          </div>
          <div className="absolute top-[23rem] right-[10%] 3xl:top-[28rem] 3xl:right-[10.5%] mr-3 flex flex-col justify-center items-center opacity-0">
            <div
              className="uppercase text-center leading-none cursor-pointer mb-2"
              onClick={() => {
                window.open('https://maeta.komi.io/#bf714197-7be8-4b1d-ac93-e065b0af6a1f');
              }}
            >dj got me<br/>single</div>
            <div
              className="bg-white rounded-full p-2 w-1 cursor-pointer"
              onClick={() => {
                window.open('https://maeta.komi.io/#bf714197-7be8-4b1d-ac93-e065b0af6a1f');
              }}
            ></div>
          </div>
          <div className="absolute bottom-[14rem] right-[4%] 3xl:bottom-[17rem] 3xl:right-[4%] mr-4 mb-[0.4rem] flex flex-col justify-center items-center opacity-0">
            <div
              className="bg-white rounded-full px-6 py-2 w-1 cursor-pointer text-black flex justify-center items-center text-2xl"
              onClick={() => {
                window.open('http://maetasworld.com/');
              }}
            >M</div>
          </div>
          <div className="absolute top-[16rem] left-[57%] 3xl:top-[19rem] 3xl:left-[57.5%] -ml-1 flex flex-col justify-center items-center opacity-0">
            <div
              className="bg-white rounded-full p-2 w-1 cursor-pointer"
              onClick={() => {
                const vidBlink = blinkingRef.current as HTMLVideoElement;
                const transitionVid = transitionRef.current as HTMLVideoElement;
                if (isMobile) transitionVid.load();
                // console.log(vidBlink.currentTime, transitionVid.readyState);
                const checkBlink = setInterval(function() {
                  if (vidBlink.currentTime > 1.8 && transitionVid.readyState == 4) {
                    setChangeColor(prevStatus => !prevStatus);
                    clearInterval(checkBlink);
                  }
                }, 100);
              }}
            ></div>
            <div
              className="uppercase cursor-pointer"
              onClick={() => {
                const vidBlink = blinkingRef.current as HTMLVideoElement;
                const transitionVid = transitionRef.current as HTMLVideoElement;
                if (isMobile) transitionVid.load();

                const checkBlink = setInterval(function() {
                  console.log(vidBlink.currentTime, transitionVid.readyState);
                  if (vidBlink.currentTime > 1.8 && transitionVid.readyState == 4) {
                    setChangeColor(prevStatus => !prevStatus);
                    clearInterval(checkBlink);
                  }
                }, 100);
              }}
            >CLICK HERE</div>
          </div>
        </Box>
      {/* ) : null} */}

      <Box className="absolute flex justify-center items-center h-[93vh] md:h-screen w-full">
        {showPopupTable && (
          <Box className="w-full h-full absolute z-50">
            <Box className="backdrop-blur-[10px] w-full h-full"></Box>
            <Box className="h-auto md:h-[55vh] w-11/12 md:w-1/2 fixed md:absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[100] rounded-2xl shadow-sm">
              <button
                className="text-md absolute top-2 right-2 bg-white pt-1 pb-0.5 px-2 text-black rounded-full z-[101]"
                onClick={() => {
                  setShowPopupTable(false);
                }}
              >
                <FontAwesomeIcon icon={faClose as any} />
              </button>
              <VideoPlayer
                id="video_bar"
                ref={barVideRef}
                preload="auto"
                playsInline
                // autoPlay
                controls
                src={BARVID}
                poster={VIDPOSTER}
                className="h-full w-full object-cover block object-center rounded-2xl border-2 border-white bg-black"
              ></VideoPlayer>
              <Box className="flex flex-col justify-center items-center text-center text-lg mt-2" style={{textShadow: '2px 2px #000'}}>
                Enter your number to be able to join Maeta for a night out!
                <Box className="flex mt-1">
                  <input type="tel" id="phone_number" className="px-2 w-full text-black" placeholder="Enter your number" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" />
                  <button
                    className="bg-white text-black mx-2 px-3 py-1 uppercase rounded"
                    onClick={() => {
                      let data = {
                        "Email": usersEmail,
                        "Phone Number": $("#phone_number").val()
                      };

                      if ($("#phone_number").val() !== '')
                        sendData(data);
                    }}
                  >Submit</button>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        {showPopupRecipe && (
          <Box className="w-full h-full absolute z-50">
            <Box className="backdrop-blur-[10px] w-full h-full"></Box>
            <Box className="h-[70vh] w-11/12 md:w-2/5 fixed md:absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[100] bg-white overflow-x-hidden rounded-2xl shadow-sm">
              <button
                className="text-md absolute top-2 right-2 bg-black pt-1 pb-0.5 px-2 text-white rounded-full z-[101]"
                onClick={() => {
                  setShowPopupRecipe(false);
                }}
              >
                <FontAwesomeIcon icon={faClose as any} />
              </button>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default Showcase;
